<template>
  <v-container v-if="!fetchinData">
    <PlanInfoHeader :id="planId" :companyId="companyId" />

    <v-chip
      v-if="config.inTermination === '1'"
      class="ma-2"
      color="red"
      text-color="white"
    >
      Der Vertrag wurde zum {{ whatTerminationDate() }} gekündigt.
    </v-chip>

    <div style="width: 100%; overflow: auto">
      <v-row no-gutters style="min-width: 800px">
        <v-col cols="12">
          <v-switch
            v-model="editable"
            label="Plan editieren"
            @change="editmodeInfo()"
          ></v-switch>
        </v-col>
        <v-col cols="4">
          <table class="default-table">
            <thead>
              <tr class="primary white--text">
                <th class="white--text"></th>
                <th class="white--text"></th>
                <th class="white--text">MO</th>
                <th class="white--text">DATE</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in staticSection"
                :key="index"
                :class="typeClass(item.type)"
              >
                <template v-if="item.type === 'month'">
                  <td
                    v-if="
                      dragSection[index].isLocked === '1' ||
                      dragSection[index].isLocked === 1 ||
                      dragSection[index].isDisabled === '1' ||
                      dragSection[index].isDisabled === 1
                    "
                  >
                    <v-btn
                      class="white--text"
                      fab
                      x-small
                      color="green"
                      :loading="progress"
                      @click="lockUpdate(index, '0')"
                    >
                      <v-icon dark> mdi-lock </v-icon>
                    </v-btn>
                  </td>

                  <td v-else>
                    <v-btn
                      class="white--text"
                      fab
                      x-small
                      color="grey"
                      :loading="progress"
                      @click="lockUpdate(index, '1')"
                    >
                      <v-icon dark> mdi-lock-open </v-icon>
                    </v-btn>
                  </td>

                  <td v-if="dragSection[index].status === 'available'">
                    <v-btn
                      class="white--text"
                      fab
                      x-small
                      color="red"
                      :loading="progress"
                      :disabled="
                        dragSection[index].isLocked === '1' ||
                        dragSection[index].isLocked === 1 ||
                        dragSection[index].isDisabled === '1' ||
                        dragSection[index].isDisabled === 1 ||
                        !editable
                          ? true
                          : false
                      "
                      @click="clearSlot(index)"
                    >
                      <v-icon dark> mdi-trash-can </v-icon>
                    </v-btn>
                  </td>

                  <td v-else>
                    <v-btn
                      class="white--text"
                      fab
                      x-small
                      color="green"
                      :disabled="
                        dragSection[index].isLocked === '1' ||
                        dragSection[index].isLocked === 1 ||
                        dragSection[index].isDisabled === '1' ||
                        dragSection[index].isDisabled === 1 ||
                        !editable
                          ? true
                          : false
                      "
                      @click="addNewSlot(index)"
                    >
                      <v-icon dark> mdi-plus </v-icon>
                    </v-btn>
                  </td>

                  <td class="caption font-weight-bold">
                    {{ item.month }}/{{ item.year }}
                  </td>

                  <td v-if="dragSection[index].status !== 'blank'">
                    <v-select
                      v-model="item.date"
                      :items="noDisabledDates(item.dateOptions)"
                      label="Sonntag"
                      solo
                      hide-details
                      dense
                      :disabled="isDisabled(dragSection[index].isLocked)"
                      @change="putAction()"
                    ></v-select>
                  </td>

                  <td v-else></td>
                </template>

                <template v-else-if="item.type === 'video'">
                  <td
                    class="blue-grey darken-2"
                    v-if="
                      dragSection[index].isLocked === '1' ||
                      dragSection[index].isLocked === 1
                    "
                  >
                    <v-btn
                      class="white--text"
                      fab
                      x-small
                      color="green"
                      :loading="progress"
                      @click="videoLockUpdate(index, '0')"
                    >
                      <v-icon dark> mdi-lock </v-icon>
                    </v-btn>
                  </td>

                  <td v-else class="blue-grey darken-2">
                    <v-btn
                      class="white--text"
                      fab
                      x-small
                      color="grey"
                      :loading="progress"
                      @click="videoLockUpdate(index, '1')"
                    >
                      <v-icon dark> mdi-lock-open </v-icon>
                    </v-btn>
                  </td>

                  <td
                    v-if="dragSection[index].status === 'available'"
                    class="blue-grey darken-2"
                  >
                    <v-btn
                      class="white--text"
                      fab
                      x-small
                      color="red"
                      :loading="progress"
                      :disabled="
                        dragSection[index].isLocked === '1' ||
                        dragSection[index].isLocked === 1 ||
                        dragSection[index].isDisabled === '1' ||
                        dragSection[index].isDisabled === 1 ||
                        !editable
                          ? true
                          : false
                      "
                      @click="clearVideoSlot(index)"
                    >
                      <v-icon dark> mdi-trash-can </v-icon>
                    </v-btn>
                  </td>

                  <td v-else class="blue-grey darken-2">
                    <v-btn
                      class="white--text"
                      fab
                      x-small
                      color="green"
                      :loading="progress"
                      :disabled="
                        dragSection[index].isLocked === '1' ||
                        dragSection[index].isLocked === 1 ||
                        dragSection[index].status !== 'blank' ||
                        !editable
                          ? true
                          : false
                      "
                      @click="addNewVideoSlot(index)"
                    >
                      <v-icon dark> mdi-plus </v-icon>
                    </v-btn>
                  </td>

                  <td
                    class="caption font-weight-bold blue-grey darken-2 white--text"
                    colspan="2"
                  >
                    Quartalsvideo
                  </td>
                </template>
              </tr>
            </tbody>
          </table>
        </v-col>

        <v-col cols="8">
          <table class="default-table">
            <thead>
              <tr class="primary white--text">
                <th class="white--text">Kampagne / Video</th>
                <th class="white--text">BUDGET</th>
                <th class="white--text">CHAT</th>
                <th class="white--text"></th>
              </tr>
            </thead>

            <draggable
              v-model="dragSection"
              tag="tbody"
              :move="handleMove"
              @end="handleDragEnd"
              draggable=".is-draggable"
              handle=".draghandle"
            >
              <tr
                v-for="(item, index) in dragSection"
                :key="index"
                :class="
                  draggableClass(item.isLocked) + ' ' + typeClass(item.type)
                "
              >
                <template v-if="item.type === 'month'">
                  <template v-if="item.status === 'available'">
                    <td>
                      <v-select
                        v-model="item.campaigns_id"
                        :items="campaigns"
                        label="Kampagne"
                        item-text="name"
                        item-value="id"
                        solo
                        hide-details
                        dense
                        :disabled="isDisabled(dragSection[index].isLocked)"
                        @change="putAction()"
                      ></v-select>
                    </td>

                    <td>
                      <v-select
                        v-model="item.budget"
                        :items="budgets"
                        label="Budget"
                        solo
                        hide-details
                        dense
                        :disabled="isDisabled(dragSection[index].isLocked)"
                        @change="putAction()"
                      ></v-select>
                    </td>

                    <td>
                      <SlotChat
                        :slotNo="staticSection[index].id"
                        :planId="planId"
                        :key="chatComponentKey"
                      />
                    </td>

                    <td
                      v-if="
                        item.isLocked !== '1' && item.isLocked !== 1 && editable
                      "
                      class="draghandle"
                    >
                      <v-icon>mdi-drag</v-icon>
                    </td>
                    <td v-else>
                      <v-icon disabled style="opacity: 0.3">mdi-drag</v-icon>
                    </td>
                  </template>

                  <template v-else>
                    <td colspan="3" class="text-center"></td>

                    <td
                      v-if="
                        item.isLocked !== '1' && item.isLocked !== 1 && editable
                      "
                      class="draghandle"
                    >
                      <v-icon disabled>mdi-drag</v-icon>
                    </td>
                    <td v-else>
                      <v-icon disabled style="opacity: 0.3">mdi-drag</v-icon>
                    </td>
                  </template>
                </template>

                <template v-else-if="item.type === 'video'">
                  <template v-if="item.status === 'available'">
                    <td class="blue-grey darken-2">
                      <v-select
                        v-model="item.video"
                        :items="videos"
                        item-text="name"
                        item-value="id"
                        label="Video"
                        solo
                        hide-details
                        dense
                        :disabled="isDisabled(dragSection[index].isLocked)"
                        @change="putAction()"
                      ></v-select>
                    </td>

                    <td style="width: 40px" class="blue-grey darken-2">
                      <v-btn
                        v-if="previewUrl(item)"
                        fab
                        dark
                        x-small
                        color="blue"
                        elevation="0"
                        :loading="progress"
                        :href="previewUrl(item)"
                        target="_blank"
                      >
                        <v-icon dark>mdi-eye</v-icon>
                      </v-btn>
                      <v-btn v-else fab x-small elevation="0" disabled>
                        <v-icon dark>mdi-eye-off</v-icon>
                      </v-btn>
                    </td>

                    <td colspan="4" class="blue-grey darken-2">
                      <v-select
                        v-model="item.branding"
                        :items="brandings"
                        item-text="name"
                        item-value="value"
                        label="Branding"
                        solo
                        hide-details
                        dense
                        :disabled="isDisabled(dragSection[index].isLocked)"
                        @change="putAction()"
                      ></v-select>
                    </td>
                  </template>

                  <template v-else>
                    <td
                      colspan="6"
                      class="text-center blue-grey darken-2 white--text"
                    ></td>
                  </template>
                </template>
              </tr>
            </draggable>
          </table>
        </v-col>
      </v-row>
    </div>
  </v-container>
  <v-container v-else>
    <v-skeleton-loader
      min-height="100vh"
      type="table-heading, table-thead, table-row-divider@16, table-tfoot"
    ></v-skeleton-loader>
  </v-container>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
import draggable from "vuedraggable";
import SlotChat from "@/components/companies/addons/annual_marketing/SlotChat.vue";
import PlanInfoHeader from "@/components/companies/addons/annual_marketing/PlanInfoHeader.vue";

export default {
  name: "Plan",

  components: {
    draggable,
    SlotChat,
    PlanInfoHeader,
  },

  props: ["planId", "companyId"],

  data() {
    return {
      chatComponentKey: 0,
      fetchinData: false, // should be true when fetching data
      progress: false,
      chatDialog: false,
      chatId: null,
      branches_id: null,
      title: null,
      config: [],
      videos: [],
      campaigns: [],
      editable: false,
      budgets: [
        "200.00",
        "300.00",
        "400.00",
        "500.00",
        "600.00",
        "700.00",
        "800.00",
        "900.00",
        "1000.00",
        "1100.00",
        "1200.00",
      ],
      dragSection: [],
      staticSection: [],
      brandings: [
        {
          name: "Premium Branding (+400,– €)",
          value: "1",
        },
        {
          name: "Video liegt bereits vor",
          value: "2",
        },
      ],
      futureItem: null,
      movingItem: null,
      futureIndex: null,
      movingIndex: null,
      targetIndex: null,
      sourceIndex: null,
      targetId: null,
      sourceId: null,

      currentTimestamp: Math.floor(Date.now() / 1000),
    };
  },

  created: function () {
    this.getData();
  },

  methods: {
    noDisabledDates(options) {
      // set disabled false for all options
      options.forEach((item) => {
        item.disabled = false;
      });

      return options;
    },

    whatTerminationDate() {
      let terminationDate = this.config.terminationDate;

      if (terminationDate) {
        let date = new Date(terminationDate);
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        return `${day}.${month}.${year}`;
      } else {
        return "";
      }
    },

    previewUrl(item) {
      const id = item.campaigns_id;
      const videoId = item.video;

      let url = null;

      this.campaigns.forEach((el) => {
        if (el.id === id) {
          url = el.previewUrl;
        }
      });

      this.videos.forEach((el) => {
        if (el.id === videoId) {
          url = el.previewUrl;
        }
      });

      return url;
    },

    async clearSlot(index) {
      const userChoice = await this.$dialog.confirm({
        text: `Soll dieser Slot wirklich für den Kunden gelöscht werden?`,
        title: "Achtung!",
        waitForResult: true,
      });

      if (userChoice) {
        this.dragSection[index].budget = null;
        this.dragSection[index].campaignPreview = null;
        this.dragSection[index].campaigns_id = null;
        this.dragSection[index].status = "blank";
        this.dragSection[index].type = "month";
        this.staticSection[index].startSunNum = null;
        this.putAction();
      }
    },

    async clearVideoSlot(index) {
      const userChoice = await this.$dialog.confirm({
        text: `Soll dieser Video-Slot wirklich für den Kunden gelöscht werden?`,
        title: "Achtung!",
        waitForResult: true,
      });

      if (userChoice) {
        const dragData = {
          branding: null,
          quarter: this.getQuarter(index),
          status: "blank",
          type: "video",
          video: null,
        };

        this.$set(this.dragSection, index, dragData);

        this.putAction();
      }
    },

    addNewSlot(index) {
      const slotData = {
        month: this.staticSection[index].month,
        status: "available",
        type: "month",
        campaigns_id: null,
        budget: null,
      };

      this.$set(this.dragSection, index, slotData);

      this.putAction();
    },

    addNewVideoSlot(index) {
      const slotData = {
        quarter: this.getQuarter(index),
        video: null,
        branding: "1",
        status: "available",
        type: "video",
      };

      this.$set(this.dragSection, index, slotData);

      this.putAction();
    },

    getQuarter(index) {
      if (index === 0) {
        return 1;
      } else if (index === 3) {
        return 2;
      } else if (index === 7) {
        return 3;
      } else {
        return 4;
      }
    },

    whatMonth(num) {
      switch (num) {
        case "1":
          return "Januar";
        case "2":
          return "Februar";
        case "3":
          return "März";
        case "4":
          return "April";
        case "5":
          return "Mai";
        case "6":
          return "Juni";
        case "7":
          return "Juli";
        case "8":
          return "August";
        case "9":
          return "September";
        case "10":
          return "Oktober";
        case "11":
          return "November";
        case "12":
          return "Dezember";
      }
    },

    async lockUpdate(index, state) {
      const json = {
        month: {
          id: this.staticSection[index].id,
          isLocked: state,
        },
      };

      const customContentType = "json";

      this.progress = true;
      let response = await this.patchRequest(
        "plans/" + this.planId,
        json,
        customContentType
      );
      this.progress = false;

      if (response.status === 200) {
        this.dragSection[index].isLocked = state;

        let message = "Der Monat wurde erfolgreich gesperrt";
        if (state === "0") {
          message = "Der Monat wurde erfolgreich entsperrt";
        }

        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = message;
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async videoLockUpdate(index, state) {
      const json = {
        quarter: {
          id: this.staticSection[index].id,
          isLocked: state,
        },
      };

      const customContentType = "json";

      this.progress = true;
      let response = await this.patchRequest(
        "plans/" + this.planId,
        json,
        customContentType
      );
      this.progress = false;

      if (response.status === 200) {
        this.dragSection[index].isLocked = state;

        let message = "Das Quartalsvideo wurde erfolgreich gesperrt";
        if (state === "0") {
          message = "Das Quartalsvideo wurde erfolgreich entsperrt";
        }

        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = message;
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async putAction() {
      /* 
       this.futureItem = this.dragSection[this.futureIndex];
      this.movingItem = this.dragSection[this.movingIndex]; */

      let dataArr = new URLSearchParams();
      dataArr.append("dragSection", JSON.stringify(this.dragSection));
      dataArr.append("staticSection", JSON.stringify(this.staticSection));
      dataArr.append("targetIndex", JSON.stringify(this.targetIndex));
      dataArr.append("sourceIndex", JSON.stringify(this.sourceIndex));
      dataArr.append("targetId", JSON.stringify(this.targetId));
      dataArr.append("sourceId", JSON.stringify(this.sourceId));

      this.progress = true;
      const response = await this.putRequest("plans/" + this.planId, dataArr);
      this.progress = false;

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Datensatz wurde aktualisiert";
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;

        // mutate
        /* if (response && response.data.data && response.data.data.dragSection) {
          this.dragSection = response.data.data.dragSection;
          this.staticSection = response.data.data.staticSection;
        } */

        if (this.targetId) {
          // targetId is set only when a swap was made
          this.chatComponentKey += 1; // re-render the chat component
        }
      } else {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t("messageError");
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
        this.getData(); // refetch to get the real state
      }

      // reset
      this.targetIndex = null;
      this.sourceIndex = null;
      this.targetId = null;
      this.sourceId = null;
    },

    async getData() {
      this.fetchinData = true;
      let response = await this.getRequest(
        "companies/" +
          this.companyId +
          "/addons/annualMarketing/plans/" +
          this.planId
      );

      if (response.status === 200) {
        if (this.companyId === response.data.data.companies_id) {
          // Dirty Security Hack: show plan only, when current company === plan owner
          // todo: Das muss natürlich serverseitig passieren. ISSUE https://github.com/Toge360/mitfit-api/issues/38
          this.planId = response.data.data.id;
          this.dragSection = response.data.data.dragSection;
          this.staticSection = response.data.data.staticSection;
          this.title = response.data.data.title;
          this.branches_id = response.data.data.branches_id;
          this.pageTitle =
            "Jahresmarketing " + this.getSeason(response.data.data.startYear);
          this.config = response.data.data.config;
        }
      }

      //public/products Filter: ?filter[branchIds]=1,3&filter[typeId]=2&filter[categoryIds]=1,2&filter[search]=Suchbegriff
      let productsResponse = await this.getRequest(
        "public/products?filter[branchId]=" +
          this.branches_id +
          "&filter[typeId]=1&filter[isJmp]=1"
      );
      if (productsResponse) {
        /* Hier muss ich einen neuen Array generieren lassen,
        da die Produkte noch mal in einem Object liegen 1: {...}, 2: {...} */
        const that = this;
        Object.keys(productsResponse.data.data).forEach(function (k) {
          that.campaigns.push(productsResponse.data.data[k]);
        });
      }

      let videosResponse = await this.getRequest(
        "public/products?filter[branchId]=" +
          this.branches_id +
          "&filter[typeId]=2&filter[isJmp]=1"
      );
      if (videosResponse) {
        /* Hier muss ich einen neuen Array generieren lassen,
        da die Produkte noch mal in einem Object liegen 1: {...}, 2: {...} */
        const that = this;
        Object.keys(videosResponse.data.data).forEach(function (k) {
          that.videos.push(videosResponse.data.data[k]);
        });
      }

      this.fetchinData = false;
    },

    getSeason(startYear) {
      return startYear + "/" + (parseInt(startYear) + 1);
    },

    isDisabled(state) {
      if (state === "1" || state === 1 || !this.editable) {
        return true;
      }

      return false;
    },

    draggableClass(state) {
      if (state === "0" && this.editable) {
        return "is-draggable";
      } else {
        return "is-locked";
      }
    },

    typeClass(state) {
      if (state === "month") {
        return "type-month";
      } else if (state === "blank") {
        return "type-blank";
      } else if (state === "video") {
        return "type-video";
      }
    },

    handleDragEnd() {
      /* SWAP-Hack: https://fir-vue-draggable.firebaseapp.com/swap/code */

      this.futureItem = this.dragSection[this.futureIndex];
      this.movingItem = this.dragSection[this.movingIndex];

      this.targetIndex = this.futureIndex;
      this.sourceIndex = this.movingIndex;

      this.targetId = this.staticSection[this.futureIndex].id;
      this.sourceId = this.staticSection[this.movingIndex].id;

      if (
        this.futureItem &&
        this.futureItem !== this.movingItem &&
        this.futureItem.isLocked !== "1" &&
        this.futureItem.isDisabled !== "1" &&
        this.futureItem.type !== "video"
      ) {
        const _items = Object.assign([], this.dragSection);
        _items[this.futureIndex] = this.movingItem;
        _items[this.movingIndex] = this.futureItem;

        // nullen des dates wenn ein leer monat

        this.dragSection = _items;

        this.futureIndex = null;
        this.movingIndex = null;

        // nullen des dates wenn ein leer monat
        this.dragSection.forEach((item, index) => {
          if (item.type === "month" && item.status === "blank") {
            this.staticSection[index].date = null;

            console.log(this.staticSection);
          }
        });

        this.putAction();
      }
    },

    handleMove(e) {
      /* SWAP-Hack: https://fir-vue-draggable.firebaseapp.com/swap/code */

      this.movingIndex = e.draggedContext.index;
      this.futureIndex = e.draggedContext.futureIndex;
      return false; // disable sort
    },

    addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },

    formatDate(date) {
      const dt = new Date(date);
      const d = this.addZero(dt.getDate()); //Month from 0 to 11
      const m = this.addZero(dt.getMonth() + 1); //Month from 0 to 11
      const y = dt.getFullYear();
      return d + "." + m + "." + y;
    },

    editmodeInfo() {
      if (this.editable) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Der Plan ist nun editierbar";
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      } else {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Plan ist nun nicht mehr editierbar";
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },
  },

  mixins: [apiRequest, helpers],
};
</script>

<style>
.default-table {
  width: 100% !important;
  float: left;
}

.default-table td {
  height: 70px !important;
  overflow: hidden;
}

.default-table td.w30 {
  width: 30px;
}

.draghandle {
  cursor: pointer;
}

.jmp-table .type-blank {
  background-color: white;
}

.jmp-table .type-regular {
  background-color: white;
}

.jmp-table .type-video {
  background-color: #222222 !important;
  color: white;
  min-height: 5px;
}

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: rgba(0, 0, 0, 0.01);
}
</style>
