<template>
  <v-container>
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row>
      <v-col cols="8">
        <DataTable
          @tableAction="emittedAction"
          :items="items"
          :headers="headers"
          :itemActions="{
            edit: true,
          }"
          :preloader="preloader"
        />
      </v-col>

      <v-col cols="4">
        <Subnavigation root="/csm" :pageTitle="pageTitle"></Subnavigation>
      </v-col>
    </v-row>

    <v-dialog
      v-model="planDialog"
      fullscreen
      transition="dialog-bottom-transition"
      scrollable
      persistent
      :key="rerenderKey"
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-toolbar-title>Plan</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="planDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
          <Plan :planId="planId" :companyId="companyId" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
import PageHeader from "@/components/ui/PageHeader.vue";
import DataTable from "@/components/ui/DataTable.vue";
import Plan from "@/components/companies/addons/annual_marketing/Plan.vue";
import Subnavigation from "@/components/ui/Subnavigation.vue";
export default {
  name: "AnnualMarketingPlans",

  components: {
    PageHeader,
    DataTable,
    Plan,
    Subnavigation,
  },

  data() {
    return {
      rerenderKey: 0,
      planDialog: false,
      planId: null,
      pageTitle: "Jahresmarketing Flex - Alle Pläne",
      pageDescription: "Alle aktiven Pläne in der Übersicht",
      preloader: false,
      data: [],
      items: [],
      headers: [],
      companyId: null,
    };
  },

  created: function () {
    this.getData(); // call it immediatly
  },

  methods: {
    async getData() {
      this.preloader = true;
      let response = await this.getRequest("plans");
      if (response.status === 200) {
        this.data = response.data.data;

        this.headers = [
          { text: "ID", value: "id" },
          { text: "Saison", value: "startYear" },
          { text: "Firma", value: "companyName" },
          { text: "Branche", value: "branchName" },
          { text: "Erstellungsdatum", value: "created" },
          { text: "Änderungsdatum", value: "updated" },
          { text: "Template", value: "templateName" },
          { text: "Actions", value: "actions", sortable: false },
        ];

        this.items = response.data.data;
      }
      this.preloader = false;
    },

    async emittedAction(value) {
      if (value.action === "editItem") {
        this.companyId = value.item.companies_id;
        this.planId = value.itemId;
        this.rerenderKey += 1;
        this.planDialog = true;
      }
    },
  },

  mixins: [apiRequest, helpers],
};
</script>
