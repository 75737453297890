<template>
  <v-card v-if="!fetchingData" class="my-2">
    <v-card-title>Info</v-card-title>
    <v-card-text>
      <strong>Kunde:</strong> {{ data.companyName }} <br />
      <strong>Zeitraum:</strong> {{ getSeason(data.startYear) }} <br />
      <strong>Branche:</strong> {{ data.branchName }} <br />
      <strong>Template:</strong> {{ data.templateName }} <br />
      <strong>ID:</strong> {{ data.id }} <br />
    </v-card-text>
  </v-card>
  <v-skeleton-loader v-else type="card"></v-skeleton-loader>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";

export default {
  name: "PlanInfoHeader",
  components: {},
  props: ["id", "companyId"],

  data() {
    return {
      fetchingData: false,
      data: null,
    };
  },

  created: function () {
    this.getData();
  },

  methods: {
    async getData() {
      this.fetchingData = true;
      let response = await this.getRequest(
        "companies/" +
          this.companyId +
          "/addons/annualMarketing/plans/" +
          this.id
      );

      if (response.status === 200) {
        if (this.companyId === response.data.data.companies_id) {
          // Dirty Security Hack: show plan only, when current company === plan owner
          // todo: Das muss natürlich serverseitig passieren. ISSUE https://github.com/Toge360/mitfit-api/issues/38
          this.data = response.data.data;
        }
      }
      this.fetchingData = false;
    },
    getSeason(startYear) {
      return startYear + "/" + (parseInt(startYear) + 1);
    },
  },

  mixins: [apiRequest, helpers],
};
</script>
