var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.fetchinData)?_c('v-container',[_c('PlanInfoHeader',{attrs:{"id":_vm.planId,"companyId":_vm.companyId}}),(_vm.config.inTermination === '1')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"red","text-color":"white"}},[_vm._v(" Der Vertrag wurde zum "+_vm._s(_vm.whatTerminationDate())+" gekündigt. ")]):_vm._e(),_c('div',{staticStyle:{"width":"100%","overflow":"auto"}},[_c('v-row',{staticStyle:{"min-width":"800px"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-switch',{attrs:{"label":"Plan editieren"},on:{"change":function($event){return _vm.editmodeInfo()}},model:{value:(_vm.editable),callback:function ($$v) {_vm.editable=$$v},expression:"editable"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('table',{staticClass:"default-table"},[_c('thead',[_c('tr',{staticClass:"primary white--text"},[_c('th',{staticClass:"white--text"}),_c('th',{staticClass:"white--text"}),_c('th',{staticClass:"white--text"},[_vm._v("MO")]),_c('th',{staticClass:"white--text"},[_vm._v("DATE")])])]),_c('tbody',_vm._l((_vm.staticSection),function(item,index){return _c('tr',{key:index,class:_vm.typeClass(item.type)},[(item.type === 'month')?[(
                    _vm.dragSection[index].isLocked === '1' ||
                    _vm.dragSection[index].isLocked === 1 ||
                    _vm.dragSection[index].isDisabled === '1' ||
                    _vm.dragSection[index].isDisabled === 1
                  )?_c('td',[_c('v-btn',{staticClass:"white--text",attrs:{"fab":"","x-small":"","color":"green","loading":_vm.progress},on:{"click":function($event){return _vm.lockUpdate(index, '0')}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-lock ")])],1)],1):_c('td',[_c('v-btn',{staticClass:"white--text",attrs:{"fab":"","x-small":"","color":"grey","loading":_vm.progress},on:{"click":function($event){return _vm.lockUpdate(index, '1')}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-lock-open ")])],1)],1),(_vm.dragSection[index].status === 'available')?_c('td',[_c('v-btn',{staticClass:"white--text",attrs:{"fab":"","x-small":"","color":"red","loading":_vm.progress,"disabled":_vm.dragSection[index].isLocked === '1' ||
                      _vm.dragSection[index].isLocked === 1 ||
                      _vm.dragSection[index].isDisabled === '1' ||
                      _vm.dragSection[index].isDisabled === 1 ||
                      !_vm.editable
                        ? true
                        : false},on:{"click":function($event){return _vm.clearSlot(index)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-trash-can ")])],1)],1):_c('td',[_c('v-btn',{staticClass:"white--text",attrs:{"fab":"","x-small":"","color":"green","disabled":_vm.dragSection[index].isLocked === '1' ||
                      _vm.dragSection[index].isLocked === 1 ||
                      _vm.dragSection[index].isDisabled === '1' ||
                      _vm.dragSection[index].isDisabled === 1 ||
                      !_vm.editable
                        ? true
                        : false},on:{"click":function($event){return _vm.addNewSlot(index)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)],1),_c('td',{staticClass:"caption font-weight-bold"},[_vm._v(" "+_vm._s(item.month)+"/"+_vm._s(item.year)+" ")]),(_vm.dragSection[index].status !== 'blank')?_c('td',[_c('v-select',{attrs:{"items":_vm.noDisabledDates(item.dateOptions),"label":"Sonntag","solo":"","hide-details":"","dense":"","disabled":_vm.isDisabled(_vm.dragSection[index].isLocked)},on:{"change":function($event){return _vm.putAction()}},model:{value:(item.date),callback:function ($$v) {_vm.$set(item, "date", $$v)},expression:"item.date"}})],1):_c('td')]:(item.type === 'video')?[(
                    _vm.dragSection[index].isLocked === '1' ||
                    _vm.dragSection[index].isLocked === 1
                  )?_c('td',{staticClass:"blue-grey darken-2"},[_c('v-btn',{staticClass:"white--text",attrs:{"fab":"","x-small":"","color":"green","loading":_vm.progress},on:{"click":function($event){return _vm.videoLockUpdate(index, '0')}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-lock ")])],1)],1):_c('td',{staticClass:"blue-grey darken-2"},[_c('v-btn',{staticClass:"white--text",attrs:{"fab":"","x-small":"","color":"grey","loading":_vm.progress},on:{"click":function($event){return _vm.videoLockUpdate(index, '1')}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-lock-open ")])],1)],1),(_vm.dragSection[index].status === 'available')?_c('td',{staticClass:"blue-grey darken-2"},[_c('v-btn',{staticClass:"white--text",attrs:{"fab":"","x-small":"","color":"red","loading":_vm.progress,"disabled":_vm.dragSection[index].isLocked === '1' ||
                      _vm.dragSection[index].isLocked === 1 ||
                      _vm.dragSection[index].isDisabled === '1' ||
                      _vm.dragSection[index].isDisabled === 1 ||
                      !_vm.editable
                        ? true
                        : false},on:{"click":function($event){return _vm.clearVideoSlot(index)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-trash-can ")])],1)],1):_c('td',{staticClass:"blue-grey darken-2"},[_c('v-btn',{staticClass:"white--text",attrs:{"fab":"","x-small":"","color":"green","loading":_vm.progress,"disabled":_vm.dragSection[index].isLocked === '1' ||
                      _vm.dragSection[index].isLocked === 1 ||
                      _vm.dragSection[index].status !== 'blank' ||
                      !_vm.editable
                        ? true
                        : false},on:{"click":function($event){return _vm.addNewVideoSlot(index)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)],1),_c('td',{staticClass:"caption font-weight-bold blue-grey darken-2 white--text",attrs:{"colspan":"2"}},[_vm._v(" Quartalsvideo ")])]:_vm._e()],2)}),0)])]),_c('v-col',{attrs:{"cols":"8"}},[_c('table',{staticClass:"default-table"},[_c('thead',[_c('tr',{staticClass:"primary white--text"},[_c('th',{staticClass:"white--text"},[_vm._v("Kampagne / Video")]),_c('th',{staticClass:"white--text"},[_vm._v("BUDGET")]),_c('th',{staticClass:"white--text"},[_vm._v("CHAT")]),_c('th',{staticClass:"white--text"})])]),_c('draggable',{attrs:{"tag":"tbody","move":_vm.handleMove,"draggable":".is-draggable","handle":".draghandle"},on:{"end":_vm.handleDragEnd},model:{value:(_vm.dragSection),callback:function ($$v) {_vm.dragSection=$$v},expression:"dragSection"}},_vm._l((_vm.dragSection),function(item,index){return _c('tr',{key:index,class:_vm.draggableClass(item.isLocked) + ' ' + _vm.typeClass(item.type)},[(item.type === 'month')?[(item.status === 'available')?[_c('td',[_c('v-select',{attrs:{"items":_vm.campaigns,"label":"Kampagne","item-text":"name","item-value":"id","solo":"","hide-details":"","dense":"","disabled":_vm.isDisabled(_vm.dragSection[index].isLocked)},on:{"change":function($event){return _vm.putAction()}},model:{value:(item.campaigns_id),callback:function ($$v) {_vm.$set(item, "campaigns_id", $$v)},expression:"item.campaigns_id"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.budgets,"label":"Budget","solo":"","hide-details":"","dense":"","disabled":_vm.isDisabled(_vm.dragSection[index].isLocked)},on:{"change":function($event){return _vm.putAction()}},model:{value:(item.budget),callback:function ($$v) {_vm.$set(item, "budget", $$v)},expression:"item.budget"}})],1),_c('td',[_c('SlotChat',{key:_vm.chatComponentKey,attrs:{"slotNo":_vm.staticSection[index].id,"planId":_vm.planId}})],1),(
                      item.isLocked !== '1' && item.isLocked !== 1 && _vm.editable
                    )?_c('td',{staticClass:"draghandle"},[_c('v-icon',[_vm._v("mdi-drag")])],1):_c('td',[_c('v-icon',{staticStyle:{"opacity":"0.3"},attrs:{"disabled":""}},[_vm._v("mdi-drag")])],1)]:[_c('td',{staticClass:"text-center",attrs:{"colspan":"3"}}),(
                      item.isLocked !== '1' && item.isLocked !== 1 && _vm.editable
                    )?_c('td',{staticClass:"draghandle"},[_c('v-icon',{attrs:{"disabled":""}},[_vm._v("mdi-drag")])],1):_c('td',[_c('v-icon',{staticStyle:{"opacity":"0.3"},attrs:{"disabled":""}},[_vm._v("mdi-drag")])],1)]]:(item.type === 'video')?[(item.status === 'available')?[_c('td',{staticClass:"blue-grey darken-2"},[_c('v-select',{attrs:{"items":_vm.videos,"item-text":"name","item-value":"id","label":"Video","solo":"","hide-details":"","dense":"","disabled":_vm.isDisabled(_vm.dragSection[index].isLocked)},on:{"change":function($event){return _vm.putAction()}},model:{value:(item.video),callback:function ($$v) {_vm.$set(item, "video", $$v)},expression:"item.video"}})],1),_c('td',{staticClass:"blue-grey darken-2",staticStyle:{"width":"40px"}},[(_vm.previewUrl(item))?_c('v-btn',{attrs:{"fab":"","dark":"","x-small":"","color":"blue","elevation":"0","loading":_vm.progress,"href":_vm.previewUrl(item),"target":"_blank"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-eye")])],1):_c('v-btn',{attrs:{"fab":"","x-small":"","elevation":"0","disabled":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-eye-off")])],1)],1),_c('td',{staticClass:"blue-grey darken-2",attrs:{"colspan":"4"}},[_c('v-select',{attrs:{"items":_vm.brandings,"item-text":"name","item-value":"value","label":"Branding","solo":"","hide-details":"","dense":"","disabled":_vm.isDisabled(_vm.dragSection[index].isLocked)},on:{"change":function($event){return _vm.putAction()}},model:{value:(item.branding),callback:function ($$v) {_vm.$set(item, "branding", $$v)},expression:"item.branding"}})],1)]:[_c('td',{staticClass:"text-center blue-grey darken-2 white--text",attrs:{"colspan":"6"}})]]:_vm._e()],2)}),0)],1)])],1)],1)],1):_c('v-container',[_c('v-skeleton-loader',{attrs:{"min-height":"100vh","type":"table-heading, table-thead, table-row-divider@16, table-tfoot"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }